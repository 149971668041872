// Customizable Area Start
import React from "react";
// Customizable Area End
import {
    // Customizable Area Start
    Container,
    Box,
    Typography,
    Grid,
    Paper,
    Button,
    Modal,
    CircularProgress
    // Customizable Area End
} from "@material-ui/core";

import DashboardController, { Props } from "./DashboardController.web";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import { LazyLoadImage } from "react-lazy-load-image-component";
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import ReactPlayer from 'react-player';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import {
    backgroundTwoHgFormula,
    backgroundOneHgFormula_new,
    img_bkg, img_bkg2, img_bkg3, img_bkg4, img_bkg5,
} from './assets';


const theme = createTheme({

    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },

    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
            },
        },
    },
});

const Boxcontainertwo = styled(Box)({
    display: 'flex', alignItems: 'center', justifyContent: 'center', margin: "auto"
  
  
  
  })

// Customizable Area End



export default class HgFormula extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    colorImageBlockStylings = [
        {
            id: 1,
            backImg: img_bkg,
            box1Style: webStyle.img_Background,
            imgStyle: webStyle.imgbkgFrist,
            box2Style: webStyle.img_BorderBox,
            lazyImgStyle: webStyle.imgFirst
        },
        {
            id: 2,
            backImg: img_bkg2,
            box1Style: webStyle.img_Background2,
            imgStyle: webStyle.imgbkgSecond,
            box2Style: webStyle.img_BorderBox2,
            lazyImgStyle: webStyle.imgSecond
        },
        {
            id: 3,
            backImg: img_bkg3,
            box1Style: webStyle.img_Background3,
            imgStyle: webStyle.imgbkgThird,
            box2Style: webStyle.img_BorderBox3,
            lazyImgStyle: webStyle.imgThird
        },
        {
            id: 4,
            backImg: img_bkg4,
            box1Style: webStyle.img_Background4,
            imgStyle: webStyle.imgbkgFourth,
            box2Style: webStyle.img_BorderBox4,
            lazyImgStyle: webStyle.imgFourth
        },
        {
            id: 5,
            backImg: img_bkg5,
            box1Style: webStyle.img_Background5,
            imgStyle: webStyle.imgbkgFifth,
            box2Style: webStyle.img_BorderBox5,
            lazyImgStyle: webStyle.imgFifth
        }
    ];

    colorHgFormulaBlocksData = [
        //ORANGE BLOCK 
        { id: 0, bgColor: '#fbaf3c' },
        //PURPLE BLOCK 
        { id: 1, bgColor: '#936AAF' },
        //YELLOW BLOCK 
        { id: 2, bgColor: '#fae03d' },
    ]

    colorMakeMyChoiceBlocksData = [
        //ORANGE BLOCK 
        { id: 0, bgColor: '#fbaf3c' },
        //PURPLE BLOCK 
        { id: 1, bgColor: '#936AAF' },
        //GREEN BLOCK 
        { id: 2, bgColor: '#97c84f' },
    ]

    colorExporeProgramCard = [
        // ORANGE BLOCK
        {id: 0, bgColor: '#FBAF73', borderColor: '#F7DE3B'},
        // GRAY BLOCK
        {id: 1, bgColor: '#A8A8A8', borderColor: '#966AB1'},
        // GREEN BLOCK
        {id: 2, bgColor: '#97C84F', borderColor: '#97C94F'},
    ]

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container maxWidth={false} style={{ margin: '0 auto', width: "100%", padding: '0' }}>

                    <AppWithoutDrawer navigation={this.props.navigation} />
                    <Grid container style={webStyle.gridContainerStyle}>
                        {/* --------------------------- section 1 - PURSUE start ------------------------- */}
                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainer }} >
                            <Grid container style={{ margin: '0', display: 'flex', boxSizing: 'border-box' }}>
                                <Grid item xs={6} sm={6} lg={6} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Box style={{ width: '65%', paddingLeft: '1.7vw' }}>
                                        <Typography style={webStyle.persue_TextStyle}>{this.state.hgFormula_PursueWhatMatters?.header}</Typography>

                                        <Box style={webStyle.discription as React.CSSProperties}>
                                            {this.state.firstDescription.map((part, index) =>
                                                part.toLowerCase() === "flexible" || part.toLowerCase() === "personalized" ? (
                                                    <Typography style={{ fontSize: '2.3vw', ...webStyle.persue_SubTextStyle_orange }}>{part}</Typography>
                                                ) : (
                                                    <Typography style={{ fontSize: '2.3vw', ...webStyle.persue_SubTextStyle }}>{part}</Typography>
                                                )
                                            )}
                                        </Box>

                                        <Box style={webStyle.discription as React.CSSProperties}>
                                            {this.state.secondDescription.map((part, index) =>
                                                part.toLowerCase() === "18,000" || part.toLowerCase() === "students" ? (
                                                    <Typography style={{ fontSize: '2.3vw', ...webStyle.persue_SubTextStyle_green }}>{part}</Typography>
                                                ) : (
                                                    <Typography style={{ fontSize: '2.3vw', ...webStyle.persue_SubTextStyle }}>{part}</Typography>
                                                )
                                            )}
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={6} lg={6}  >
                                    <Box style={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                                        <LazyLoadImage
                                            alt='Persue What Matter image'
                                            src={this.state.hgFormula_PursueWhatMatters?.hg_formula_sections?.data[0]?.attributes?.image1}
                                            style={{ objectFit: 'cover', ...webStyle.imgPersue }}
                                            effect="blur"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            {/* --------------------------- section 1 - PURSUE end ------------------------- */}
                            {/* --------------------------- section 2 - Hg Formula start ------------------------- */}
                            <div id='link1'></div>
                            <Grid container style={{ margin: '0', display: 'flex', boxSizing: 'border-box' }}>
                                <Grid item xs={12} style={webStyle.gridItemStyle}>
                                    <Box style={{ marginTop: '-1vw', ...webStyle.orange_TitleBox }}>
                                        <Typography style={webStyle.orange_TitleTextStyle}>
                                            {this.state.hgFormula_TheHgFormula?.header}
                                        </Typography>
                                    </Box>

                                    <Box style={{ width: '40%', ...webStyle.orange_SubTitleBox }}>
                                        <Typography align='center' style={webStyle.orange_SubTitleTextStyle}>
                                            {this.state.hgFormula_TheHgFormula?.description}
                                        </Typography>
                                    </Box>
                                </Grid>


                                {this.colorHgFormulaBlocksData?.map((data) => {
                                    return (
                                        <>

                                            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                                <Grid item xs={3} sm={3} lg={3}>
                                                    <Box style={{ backgroundColor: data.bgColor, ...webStyle.block_SideBox }}>
                                                        <Typography style={webStyle.orange_HeadingStyle}>
                                                            {this.getHgFormulaTitle(data.id)}
                                                        </Typography>
                                                        <Typography style={webStyle.orange_SubHeadingStyle}>
                                                            {this.getHgFormulaSubTitle(data.id)}
                                                        </Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={7} sm={7} lg={7} style={webStyle.gridItemStyle2}>
                                                    <Box style={webStyle.orange_ContentBox}>
                                                        <Typography dangerouslySetInnerHTML={{ __html: this.getHgFormulaDescription(data.id) }} style={webStyle.hg_ContentTextStyle} />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2} sm={2} lg={2} style={webStyle.gridItemStyle3} >
                                                    <img src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[0].attributes?.image6}
                                                        style={{ objectFit: 'cover', ...webStyle.plusStyle }} />
                                                </Grid>
                                            </Grid>

                                            <Grid container style={{ display: 'flex', width: '100%', alignItems: 'flex-end', justifyContent: 'center', marginLeft: '3vw', marginBottom: '2vw' }}>

                                                {this.colorImageBlockStylings.map((item) => {
                                                    return (
                                                        <Grid item xs={2} sm={2} md={2} lg={2}>
                                                            <Box style={item.box1Style}>
                                                                <img src={item.backImg} style={{ objectFit: 'cover', ...item.imgStyle }} />
                                                                <Box style={{ zIndex: 10, ...item.box2Style }}>
                                                                    <LazyLoadImage
                                                                        effect="blur"
                                                                        src={this.getHgFormulaSectionImage(data.id, item.id)}
                                                                        alt='image1'
                                                                        style={{ objectFit: 'cover', ...item.lazyImgStyle }} />
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    )
                                                })}

                                            </Grid>
                                        </>
                                    )
                                })}

                                {/* --------------------------------------------- GREEN BLOCK ---------------------------------------------------------- */}
                                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item xs={3} sm={3} lg={3}>
                                        <Box style={{ backgroundColor: ' #97c84f', ...webStyle.block_SideBox }}>
                                            <Typography style={webStyle.orange_HeadingStyle}>
                                                {this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[3].attributes?.title}
                                            </Typography>
                                            <Typography style={webStyle.orange_SubHeadingStyle}>
                                                {this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[3].attributes?.sub_title}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={7} sm={7} lg={7} style={webStyle.gridItemStyle2}>
                                        <Box style={webStyle.orange_ContentBox}>
                                            <Typography style={webStyle.hg_ContentTextStyle}>
                                                {this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[3].attributes?.description}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sm={2} lg={2} style={webStyle.gridItemStyle3} >
                                        <img src={this.state.hgFormula_TheHgFormula?.hg_formula_sections?.data[3].attributes?.image6}
                                            style={{ objectFit: 'cover', ...webStyle.plusStyle }} />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Paper>

                        {/* ------------------------------------ PAPER CONTAINER BACKGROUND 2 start --------------------------------- */}
                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainer2 }} >
                            <Grid container style={{ display: 'flex', width: '100%', alignItems: 'flex-end', justifyContent: 'center', marginTop: '1.7vw' }}>

                                {this.colorImageBlockStylings?.map((item) =>
                                    <Grid item key={item.id} xs={2} sm={2} md={2} lg={2}>
                                        <Box style={item.box1Style}>
                                            <img src={img_bkg} style={{ objectFit: 'cover', ...item.imgStyle }} />
                                            <Box style={{ zIndex: 10, ...item.box2Style }}>
                                                <LazyLoadImage
                                                    effect="blur"
                                                    src={this.getHgFormulaSectionImage(3, item.id)}
                                                    alt='image1'
                                                    style={{ objectFit: 'cover', ...item.lazyImgStyle }} />
                                            </Box>
                                        </Box>
                                    </Grid>

                                )}
                            </Grid>

                            {/* --------------------------------- testimonial --------------------- */}
                            <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '32px' }}>
                                <Typography align="center" style={webStyle.testimonialTextStyle}>
                                    {this.state.hgFormula_TheHgFormula?.hg_formula_quote?.data.attributes?.quote}
                                </Typography>
                            </Grid>

                            <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '80%', marginTop: '20px' }}>

                                <Box>
                                    <Typography align='right' style={webStyle.cardInnerTextHeader}>
                                        {this.state.hgFormula_TheHgFormula?.hg_formula_quote?.data.attributes?.quoter_name}
                                    </Typography>
                                    <Typography align='right' style={webStyle.cardInnerTextHeader}>
                                        {this.state.hgFormula_TheHgFormula?.hg_formula_quote?.data.attributes?.quoter_position}
                                    </Typography>
                                </Box>
                                <Avatar alt="student avatar" src={this.state.hgFormula_TheHgFormula?.hg_formula_quote?.data.attributes?.avatar}
                                    style={webStyle.cardAvatarStyle} />
                            </Grid>

                            {/* -------------------------------- section2: hg foormula ends ----------------------------------------    */}

                            {/* -------------------------------- section3: making choice starts ----------------------------------------    */}
                            <Grid container style={{ marginTop: '160px', display: 'flex', boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center' }}>
                                <Grid item xs={12} style={webStyle.gridItemStyle}>
                                    <Box style={{ marginTop: '-3vw', marginBottom: '2vw', ...webStyle.orange_TitleBox }}>
                                        <Typography style={webStyle.orange_TitleTextStyle}>
                                            {this.state.hgFormula_MakingTheChoice?.header}
                                        </Typography>
                                    </Box>

                                    <Box style={{ width: '60%', ...webStyle.orange_SubTitleBox }}>
                                        <Typography align='center' style={webStyle.orange_SubTitleTextStyle}>
                                            {this.state.hgFormula_MakingTheChoice?.description}
                                        </Typography>
                                    </Box>
                                </Grid>

                                {/* ------------------------------------- CARDS start------------------------------------ */}
                                <Grid container style={{ marginTop: '50px', display: 'flex', boxSizing: 'border-box', width: '85%', alignItems: 'stretch', justifyContent: 'center' }}>
                                    {this.colorMakeMyChoiceBlocksData?.map((data) => {
                                        return (
                                            <>

                                                <Grid item xs={12} sm={4} style={webStyle.gridItemStyle}>
                                                    <Box style={{ backgroundColor: data.bgColor, ...webStyle.card_TitleBox }}>
                                                        <Typography style={webStyle.card_TitleTextStyle}>
                                                            {this.getMakeMyChoiceTitle(data.id)}
                                                        </Typography>
                                                    </Box>

                                                    <Box style={webStyle.card_ContentBox}>
                                                        <img src={this.getMakeMyChoiceImage(data.id)}
                                                            style={{ marginTop: '30px', padding: 0, objectFit: 'cover', width: '3.5vw', height: '5vw' }} />
                                                        <Typography align='justify' style={{ color: data.bgColor, ...webStyle.card_ContentTextStyle }}>
                                                            {this.getMakeMyChoiceDescription(data.id)}
                                                        </Typography>
                                                    </Box>
                                                </Grid>

                                            </>
                                        )
                                    })}
                                </Grid>

                                {/* ------------------------------------- CARDS end------------------------------------ */}

                            </Grid>

                            {/* -------------------------------- section3: making choice ends ----------------------------------------    */}

                            
                            {/* -------------------------------- section4: explore program starts ----------------------------------------    */}
                            <div ref={this.refLink2}></div>
                            <Grid  container style={{ marginTop: '12vw', marginBottom: '6vw', display: 'flex', boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center', }}>
                                <Grid item xs={12} style={webStyle.gridItemStyle}>
                                    <Box style={{ marginTop: '-3vw', marginBottom: '2vw', ...webStyle.orange_TitleBox }}>
                                        <Typography style={webStyle.orange_TitleTextStyle}>
                                            {this.state.hgFormula_ExplorePrograms?.header}
                                        </Typography>
                                    </Box>

                                    <Box style={{ width: '60%', ...webStyle.orange_SubTitleBox }}>
                                        <Typography align='center' style={webStyle.orange_SubTitleTextStyle}>
                                            {this.state.hgFormula_ExplorePrograms?.description}
                                        </Typography>
                                    </Box>
                                </Grid>

                                {/* ------------------------------------- CARDS start------------------------------------ */}
                                <Grid container style={webStyle.exploreProgramCardSection as React.CSSProperties}>
                                    {this.colorExporeProgramCard?.map((data) => {
                                        return (
                                            <>
                                                <Grid item style={webStyle.gridItemStyle}>
                                                    <Box style={webStyle.roundcard_TitleBox}>
                                                        <Typography align='left' style={{ color: data.bgColor, ...webStyle.roundcard_TitleTextStyle }}>{this.getExploreProgramBlockHeader(data.id)}</Typography>
                                                        <Typography align='left' style={webStyle.roundcard_SubTitleTextStyle}>
                                                            {this.getExploreProgramBlockDescription(data.id)}
                                                        </Typography>
                                                    </Box>
                                                    <Box style={{ border: `1px solid ${data.borderColor}`, ...webStyle.roundcard_ContentBox }}>
                                                        {this.getExploreProgramBlockInternalData(data.id)?.map((item: any, index: number) => {
                                                            return (
                                                                <Paper elevation={0} style={webStyle.gridProgramContainerStyle} data-test-id={`open-modal-${index}`} onClick={()=>this.handleModelOpen(data.id, index)}>
                                                                    <Avatar alt="program avatar"
                                                                        src={item?.attributes?.program_image}
                                                                        style={webStyle.programAvatarStyle}
                                                                    />
                                                                    <Box style={webStyle.programBoxStyle}>

                                                                        <Box>
                                                                            <Typography align='left' style={{ color: data.bgColor, ...webStyle.ProgramInnerTextHeader }}>{item?.attributes?.program_name}</Typography>
                                                                            <Typography align='left' style={webStyle.programInnerTextSubText}>
                                                                                {item?.attributes?.program_description}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Paper>
                                                            )
                                                        })}
                                                    </Box>
                                                </Grid>
                                            </>
                                        )
                                    })}
                                </Grid>

                                {/* ------------------------------------- CARDS end------------------------------------ */}

                                <Box style={webStyle.expoloreProgramBottomCenterBottomContent}>
                                    <Typography style={webStyle.expoloreProgramBottomCenterBottomContentFont}>
                                        {this.getExploreProgramBottomContent()?.quote}
                                    </Typography>
                                </Box>

                                <Box style={webStyle.quoteSection}>
                                    <Box style={{width: '45%'}}>
                                        <Typography style={webStyle.quoteName as React.CSSProperties}> {this.getExploreProgramBottomContent()?.quoter_name}</Typography>
                                        <Typography style={webStyle.quoteName as React.CSSProperties}> {this.getExploreProgramBottomContent()?.quoter_position}</Typography>
                                    </Box>
                                    <Box>
                                        <Avatar alt="student avatar" style={webStyle.quoteAvatar} src={this.getExploreProgramBottomContent()?.avatar} />
                                    </Box>
                                </Box>
                                <div ref={this.refLink3}></div>
                                <Box style={webStyle.bookContainer as React.CSSProperties}>
                                    <Grid xs={12} sm={6}>
                                        <Box style={webStyle.book1Container as React.CSSProperties}>
                                            <img style={webStyle.bookImg1 as React.CSSProperties} src={this.getExploreProgramBookData(3)?.image1} alt="img 1" />
                                            <Button data-test-id="link" onClick={()=>this.openLink(this.getExploreProgramBookData(3)?.link)} style={webStyle.downlodButton as React.CSSProperties}>{this.getExploreProgramBookData(3)?.title}</Button>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <Box style={webStyle.book1Container as React.CSSProperties}>
                                            <Box style={{display: 'flex'}}>
                                                <img style={webStyle.bookImg2 as React.CSSProperties} src={this.getExploreProgramBookData(4)?.image1} alt="img 1" />
                                                <Box style={{display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center'}}>
                                                    <img style={webStyle.bookImg3 as React.CSSProperties} src={this.getExploreProgramBookData(4)?.image2} alt="img 1" />
                                                    <Typography style={webStyle.persue_SubTextStyleImageText}>{this.getExploreProgramBookData(4)?.description}</Typography>
                                                </Box>
                                            </Box>
                                            <Button onClick={() => this.openLink(this.getExploreProgramBookData(4)?.link)} style={webStyle.downlodButton as React.CSSProperties}>{this.getExploreProgramBookData(4)?.title}</Button>
                                        </Box>
                                    </Grid>
                                </Box>
                                <div ref={this.refLink4}></div>
                            </Grid>
                        </Paper>
                        <Paper elevation={0} style={{ boxSizing: 'border-box', ...webStyle.paperContainer3 }}>
                            <Grid container style={webStyle.HGContainer as React.CSSProperties}>
                                <Grid item xs={12} style={webStyle.HgTitleGrid as React.CSSProperties}>
                                    <Box style={{ marginBottom: '2vw', ...webStyle.orange_TitleBox }}>
                                        <Typography style={webStyle.orange_TitleTextStyle}>
                                            {this.state.hgFormula_AboutHg?.header}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid container style={webStyle.VideoContainer as React.CSSProperties}>
                                    <Grid item xs={12} style={{ height: '36vw', width: '75vw' }}>
                                        <Box style={{ display: 'flex', alignItems: 'center' }} >
                                            <div ref={this.videoRef} style={webStyle.playerWrapper as React.CSSProperties}>
                                                <ReactPlayer
                                                    config={{
                                                        file: { attributes: { controlsList: 'nodownload noplaybackrate', disablePictureInPicture: true } },
                                                    }}
                                                    onContextMenu={(e: any) => {
                                                        e.preventDefault()
                                                    }}
                                                    width="100%"
                                                    height='200%'
                                                    url={this.state.hgFormula_AboutHg?.video}
                                                    muted={false}
                                                    playing={this.state.isInView}
                                                    controls={true}
                                                    pip={false}
                                                />
                                            </div>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container style={webStyle.partnerContainer as React.CSSProperties}>
                                    <Grid xs={12} sm={8} item style={webStyle.partnerOuter}>
                                        <Grid style={webStyle.HgTitleGrid as React.CSSProperties} item>
                                            <Box>
                                                <Typography style={webStyle.partnersTitleTextStyle}>{this.getHgPartner(0)?.title}</Typography>
                                            </Box>
                                            <Box>
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(0)?.image1}
                                                    effect="blur"
                                                    style={webStyle.stylePartnersLogo as React.CSSProperties}
                                                />
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(0)?.image2}
                                                    effect="blur"
                                                    style={webStyle.stylePartnersLogo as React.CSSProperties}
                                                />
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(0)?.image3}
                                                    effect="blur"
                                                    style={webStyle.stylePartnersLogo as React.CSSProperties}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid style={webStyle.HgTitleGrid as React.CSSProperties} item>
                                            <Box>
                                                <Typography style={webStyle.partnersTitleTextStyle}>{this.getHgPartner(1)?.title}</Typography>
                                            </Box>
                                            <Box>
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(1)?.image1}
                                                    effect="blur"
                                                    style={webStyle.stylePartnersLogo as React.CSSProperties}
                                                />
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(1)?.image2}
                                                    effect="blur"
                                                    style={webStyle.stylePartnersLogo as React.CSSProperties}
                                                />
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(1)?.image3}
                                                    effect="blur"
                                                    style={webStyle.stylePartnersLogo as React.CSSProperties}
                                                />
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(1)?.image4}
                                                    effect="blur"
                                                    style={webStyle.stylePartnersLogo as React.CSSProperties}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container style={webStyle.aboutHgContent as React.CSSProperties}>
                                    <Grid container xs={10} sm={8} style={webStyle.aboutHgContent as React.CSSProperties}>
                                        <Box style={webStyle.abutHgContentText as React.CSSProperties}>
                                            <Typography style={webStyle.aboutHgTextStyle as React.CSSProperties}>
                                                {this.getHgPartner(2)?.description}
                                            </Typography>
                                            <Typography style={webStyle.aboutHgTextStyle as React.CSSProperties}>
                                                {this.getHgPartner(3)?.description}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container style={webStyle.missionContainer as React.CSSProperties}>
                                    <Grid item xs={12} sm={6}>
                                        <Paper style={webStyle.missionBox as React.CSSProperties}>
                                            <LazyLoadImage
                                                alt='Persue What Matter image'
                                                src={this.getHgPartner(4)?.image1}
                                                effect="blur"
                                                style={webStyle.stylePartnersLogo as React.CSSProperties}
                                            />
                                            <Typography style={webStyle.textHeaderMissionVision}>
                                                {this.getHgPartner(4)?.title}
                                            </Typography>
                                            <Typography style={webStyle.subTexttextHeaderMissionVision}>
                                                {this.getHgPartner(4)?.description}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Paper style={webStyle.missionBox as React.CSSProperties}>
                                            <LazyLoadImage
                                                alt='Persue What Matter image'
                                                src={this.getHgPartner(5)?.image1}
                                                effect="blur"
                                                style={webStyle.stylePartnersLogo as React.CSSProperties}
                                            />
                                            <Typography style={webStyle.textHeaderMissionVision}>
                                                {this.getHgPartner(5)?.title}
                                            </Typography>
                                            <Typography style={webStyle.subTexttextHeaderMissionVision}>
                                                {this.getHgPartner(5)?.description}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid xs={12} style={webStyle.HgTitleGrid as React.CSSProperties}>
                                        <Typography style={webStyle.textUniversities} align="center">
                                            {this.getHgPartner(6)?.title}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={webStyle.gridcontainerSmallLogo}>
                                    <Grid xs={12} sm={8} style={webStyle.smallIconSection as React.CSSProperties}>
                                        <Grid item>
                                            <Box>
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(6)?.image1}
                                                    effect="blur"
                                                    style={webStyle.stylesmallLogo as React.CSSProperties}
                                                />
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(6)?.image2}
                                                    effect="blur"
                                                    style={webStyle.stylesmallLogo as React.CSSProperties}
                                                />
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(6)?.image3}
                                                    effect="blur"
                                                    style={webStyle.stylesmallLogo as React.CSSProperties}
                                                />
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(6)?.image4}
                                                    effect="blur"
                                                    style={webStyle.stylesmallLogo as React.CSSProperties}
                                                />
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(6)?.image5}
                                                    effect="blur"
                                                    style={webStyle.stylesmallLogo as React.CSSProperties}
                                                />
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(6)?.image6}
                                                    effect="blur"
                                                    style={webStyle.stylesmallLogo as React.CSSProperties}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box>
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(6)?.image7}
                                                    effect="blur"
                                                    style={webStyle.stylesmallLogo as React.CSSProperties}
                                                />
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(6)?.image8}
                                                    effect="blur"
                                                    style={webStyle.stylesmallLogo as React.CSSProperties}
                                                />
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(6)?.image9}
                                                    effect="blur"
                                                    style={webStyle.stylesmallLogo as React.CSSProperties}
                                                />
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(6)?.image10}
                                                    effect="blur"
                                                    style={webStyle.stylesmallLogo as React.CSSProperties}
                                                />
                                                <LazyLoadImage
                                                    alt='Persue What Matter image'
                                                    src={this.getHgPartner(6)?.image11}
                                                    effect="blur"
                                                    style={webStyle.stylesmallLogo as React.CSSProperties}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Box style={webStyle.expoloreProgramBottomCenterBottomContent}>
                                    <Typography style={webStyle.expoloreProgramBottomCenterBottomContentFont}>
                                        {this.getAboutHgBottomContent()?.quote}
                                    </Typography>
                                </Box>
                                <Box style={webStyle.quoteSection}>
                                    <Box style={{ width: '45%' }}>
                                        <Typography style={webStyle.quoteName as React.CSSProperties}> {this.getAboutHgBottomContent()?.quoter_name}</Typography>
                                        <Typography style={webStyle.quoteName as React.CSSProperties}> {this.getAboutHgBottomContent()?.quoter_position}</Typography>
                                    </Box>
                                    <Box>
                                        <Avatar alt="student avatar" style={webStyle.quoteAvatar} src={this.getAboutHgBottomContent()?.avatar} />
                                    </Box>
                                </Box>
                                <Grid xs={12} item style={webStyle.HgTitleGrid as React.CSSProperties}>
                                    <Box style={{ ...webStyle.orange_strip }}>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>

                    </Grid>
                </Container>
                <Grid container style={{ paddingLeft: '1em', marginTop: '2em', }}   >
                    <Modal
                        open={this.state.open}
                        onClose={this.handleModelCloseOnCross}
                    >
                        <Box style={webStyle.modalStyle}>
                            <button style={webStyle.cancelButton as React.CSSProperties} autoFocus onClick={this.handleModelCloseOnCross}>
                                <CloseOutlinedIcon fontSize="small" />
                            </button>
                            <Box>
                                <div>
                                    <Typography style={webStyle.modalTitle as React.CSSProperties}>{this.state.videoData?.title} - {this.state.videoData?.programName}</Typography>
                                </div>
                                <div style={webStyle.ModalPlayerWrapper as React.CSSProperties}>
                                    <ReactPlayer
                                        config={{ file: { attributes: { controlsList: 'nodownload noplaybackrate', disablePictureInPicture: true } } }}
                                        onContextMenu={(e: any) => {
                                            e.preventDefault()
                                        }}
                                        width="80%"
                                        height='auto'
                                        url={this.state.videoData?.programVideo}
                                        muted={false}
                                        playing={true}
                                        controls={true}
                                        pip={false}
                                    />
                                </div>
                            </Box>
                        </Box>
                    </Modal>
                </Grid>

            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    gridContainerStyle: {
        width: '100vw',
        height: '100vh',
        position: 'relative' as 'relative',
        display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        // marginTop: '50px',
    },

    btnWatchLater: {

        width: "270px",
        height: "52px",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: 600,
        color: '#fff',
        borderRadius: "10px",
        backgroundColor: "#fab03d",
        marginBottom: '2em',
    },
    avatarStyle: {
        width: '32px',
        height: '32px',
        marginRight: '10px'
    },

    imgLogoStyle: {
        width: '100%',
    },

    paperContainer: {
        width: '100%',
        //height: '3000px',
        minHeight: '500px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundOneHgFormula_new})`,
        // backgroundImage: `url(${backgroundOneHgFormula})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        // display: 'flex',
        //  alignItems: 'center',
        // justifyContent: 'center',
        // paddingTop: '0.5em',
        // paddingBottom: '4em',
        // paddingRight: '0.5em',
        // paddingLeft: '0.5em',
    },
    paperContainer2: {
        width: '100%',
        //height: '3500px',
        minHeight: '500px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundTwoHgFormula})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center top',

    },
    paperContainer3: {
        width: '100%',
        //height: '3500px',
        minHeight: '500px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundOneHgFormula_new})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center top',

    },
    persue_TextStyle: {
        color: '#9167ac',
        fontFamily: "Montserrat",
        fontSize: '5vw',
        fontWeight: 400,
        marginBottom: '0.6vw',
        marginTop: '7vw',
    },
    persue_SubTextStyle: {
        color: '#a8a8a8',
       fontFamily: "Montserrat",
        fontWeight: 700,
    },
    persue_SubTextStyle_orange: {
        color: '#fbaf3c',
       fontFamily: "Montserrat",
        fontWeight: 700,
    },
    persue_SubTextStyle_green: {
        color: '#97C84F',
       fontFamily: "Montserrat",
        fontWeight: 700,
    },
    persue_SubTextStyleImageText: {
        color: '#a8a8a8',
       fontFamily: "Montserrat",
        fontWeight: 700,
        width: "50%"
    },
    orange_TitleTextStyle: {
        color: '#ffffff',
        fontSize: '3vw',
       fontFamily: "Montserrat",
        fontWeight: 800,
        letterSpacing: '10px',
    },

    orange_TitleBox: {
        width: '100%',
        height: '7vw',
        backgroundColor: '#fbaf3c',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },


    orange_SubTitleBox: {
        marginTop: '1.7vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    orange_SubTitleTextStyle: {
        color: '#757575',
        fontSize: '1.8vw',
       fontFamily: "Montserrat",
        fontWeight: 700,
    },
    block_SideBox: {
        width: '26vw',
        height: '18vw',
        // width: '25em',
        // minHeight: '19em',
        // paddingTop:'20px',
        // paddingBottom:'20px',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },

    orange_HeadingStyle: {
        color: '#ffffff',
        fontSize: '4vw',
        // fontSize: '3.8em',
       fontFamily: "Montserrat",
        fontWeight: 500,
    },
    orange_SubHeadingStyle: {
        color: '#ffffff',
        fontSize: '2vw',
        // fontSize: '1.8em',
       fontFamily: "Montserrat",
        fontWeight: 500,
    },
    hg_ContentTextStyle: {
        color: '#757575',
        fontSize: '1.3vw',
       fontFamily: "Montserrat",
        fontWeight: 400,
    },

    orange_ContentBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        //  minHeight: '5.5em',
        //backgroundColor: '#fff'
    },

    img_Background: {
        position: 'relative' as 'relative',
        transform: 'rotate(-5deg)',
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '0.2vw'
    },

    img_BorderBox: {
        position: 'absolute' as 'absolute',
        top: '46%',
        left: '56%',
        transform: 'translate(-50%, -50%) rotate(-1deg)',
        border: '1px solid black',
        // width: '80%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center'
    },
    img_Background2: {
        position: 'relative' as 'relative',
        transform: 'rotate(-5deg)',
        // width: '105%',
        // height: 'auto',
        display: 'flex',
        marginLeft: '-1.3vw',
        marginBottom: '1vw',
    },
    img_BorderBox2: {
        position: 'absolute' as 'absolute',
        top: '46%',
        left: '53%',
        transform: 'translate(-50%, -50%) rotate(13deg)',
        border: '1px solid black',
        // width: '64%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center'
    },
    img_Background3: {
        position: 'relative' as 'relative',
        // transform: 'rotate(-5deg)',
        width: '100%',
        height: 'auto',
        display: 'flex',
        //  marginLeft: '-80px',
        marginBottom: '2vw',
        marginLeft: '-3.3vw'
    },
    img_BorderBox3: {
        position: 'absolute' as 'absolute',
        top: '47%',
        left: '58%',
        transform: 'translate(-50%, -50%) rotate(-5deg)',
        border: '1px solid black',
        // width: '80%',
        // height: 'auto',
        display: 'flex',
        alignItems: 'center'
    },
    img_Background4: {
        position: 'relative' as 'relative',
        // transform: 'rotate(-5deg)',
        width: '100%',
        height: 'auto',
        display: 'flex',
        marginLeft: '-3vw',
        // marginBottom: '15px',
    },
    img_BorderBox4: {
        position: 'absolute' as 'absolute',
        top: '47%',
        left: '55%',
        transform: 'translate(-50%, -50%) rotate(1deg)',
        border: '1px solid black',
        // width: '75%',
        // height: 'auto',
        display: 'flex',
        alignItems: 'center',

    },
    img_Background5: {
        position: 'relative' as 'relative',
        // transform: 'rotate(-5deg)',
        width: '105%',
        height: 'auto',
        display: 'flex',
        marginLeft: '-5vw',
        marginBottom: '2vw',
    },

    img_BorderBox5: {
        position: 'absolute' as 'absolute',
        top: '48%',
        left: '52%',
        transform: 'translate(-50%, -50%) rotate(13deg)',
        border: '1px solid black',
        // width: '65%',
        // height: 'auto',
        display: 'flex',
        alignItems: 'center'
    },
    testimonialTextStyle: {
        color: '#9369ae',
        fontSize: '3.5vw',
        fontFamily: "Montserrat",
        fontWeight: 400,
        width: '75%',
        // marginTop:'20px'
    },

    cardAvatarStyle: {
        width: '10vw',
        height: '10vw',
        marginLeft: '10px'
    },
    cardInnerTextHeader: {
        color: '#b8b8b8',
        // color: '#757575',
        fontFamily: "Montserrat",
        fontSize: '1.7vw',
        // fontSize: '1.5em',
        fontWeight: 1000,
    },

    card_TitleBox: {
        height: '4vw',
        width: '27vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '1.2vw'

    },

    card_TitleTextStyle: {
        color: '#ffffff',
       fontFamily: "Montserrat",
        fontSize: '1.7vw',
        fontWeight: 700,
    },

    card_ContentBox: {
        marginTop: '12px',
        width: '27vw',
        minHeight: '31vw',
        backgroundColor: '#ffffff',
        boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
    },

    card_ContentTextStyle: {
        fontFamily: "Montserrat",
        fontSize: '1.2vw',
        fontWeight: 800,
        paddingLeft: '2vw',
        paddingRight: '2vw',
        marginTop: '1.5vw',
    },

    exploreProgramCardSection: {
        marginTop: '4vw',
        display: 'flex',
        boxSizing: 'border-box',
        width: '85%',
        alignItems: 'stretch',
        justifyContent: 'center',
    },

    roundcard_TitleBox: {
        height: '15vw',
        width: '24vw',
        display: 'flex',
        // alignItems: 'center',
        flexDirection: 'column' as 'column',
    },

    roundcard_TitleTextStyle: {
       fontFamily: "Montserrat",
        fontSize: '2.4vw',
        fontWeight: 600,
    },
    roundcard_SubTitleTextStyle: {
        fontFamily: "Montserrat",
        fontSize: '1vw',
        fontWeight: 400,
        marginBottom: '1.5vw',
        color: '#92929d',
    },

    roundcard_ContentBox: {
        // marginTop: '0.3vw',
        width: '24vw',
        height: '-webkit-fill-available',
        backgroundColor: '#F5F5F5',
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        borderRadius: '10px',
        padding: '1vw',
        margin: '0 1vw'
    },

    gridProgramContainerStyle: {

        border: '1px solid #f1f1f5',
        borderRadius: '15px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 5px 15px rgba(68,68,79,0.1',
        padding: '0.5vw',
        display: 'flex',
        marginLeft: '2vw',
        marginBottom: '1vw',
        marginTop: '1vw',
        boxSizing: 'border-box' as 'border-box',
        width: '20vw',
        alignItems: 'center',
        cursor: 'pointer'
    },

    programAvatarStyle: {
        width: '5.5vw',
        height: '5.5vw',
        marginLeft: '-3.5vw',
    },

    programBoxStyle: {
        marginLeft: '8px',
        marginRight: '2px',
        display: 'flex',
        // flexWrap: 'wrap' as 'wrap',
    },

    ProgramInnerTextHeader: {
       fontFamily: "Montserrat",
        fontSize: '1.2vw',
        fontWeight: 600,
    },

    programInnerTextSubText: {
        color: ' #92929d',
        fontFamily: "Montserrat",
        fontSize: '0.9vw',
        fontWeight: 400,
    },

    chapterText: {
        color: '  #757575',
       fontFamily: "Montserrat",
        fontSize: '1.1vw',
        fontWeight: 400,
        width: '12vw'
    },
    btnExplore: {
        marginTop: '1vw',
        width: '22vw',
        height: '3.5vw',
        borderRadius: '10px',
        backgroundColor: '#fab03d',
        color: '  #fff',
       fontFamily: "Montserrat",
        fontSize: '1vw',
        fontWeight: 600,
    },
    downloadLink: {
        color: '#fff',
       fontFamily: "Montserrat",
        fontSize: '1vw',
        fontWeight: 600,
        textDecoration: 'none',
    },

    boxHgVideo: {
        width: '75vw',
        height: '36vw'
    },
    boxPartnersLogo: {
        height: '14vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    stylePartnersLogo: {
        padding: '0.3vw',
        width: '8vw',
        height: '8vw',
        objectFit: 'cover',
    },

    partnersTitleTextStyle: {
        color: '#171725',
       fontFamily: "Montserrat",
        fontSize: '1.7vw',
        fontWeight: 600,
        marginBottom: '1vw',
    },
    gridcontainerPartnerLogo: {
        boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
        backgroundColor: '#fff',
        marginTop: '1.5vw',
        display: 'flex',
        boxSizing: 'border-box' as 'border-box',
        alignItems: 'center',
        justifyContent: 'center'
    },


    aboutHgTextStyle: {
        color: '#757575',
        fontFamily: 'MontserratRoman-Regular',
        fontSize: '1.7vw',
        fontWeight: 400,
        marginBottom: '1vw',
        textAlign: 'center'
    },

    gridMissionVision: {

        border: '1px solid #f1f1f5',
        borderRadius: '15px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 5px 15px rgba(68,68,79,0.1',
        padding: '1vw',
        display: 'flex',
        flexDirection: 'column' as 'column',
        boxSizing: 'border-box' as 'border-box',
        width: '34vw',
        alignItems: 'center',
    },

    textHeaderMissionVision: {
       fontFamily: "Montserrat",
        fontSize: '1.7vw',
        fontWeight: 600,
        color: '#171725',
        marginTop: '1vw'
    },

    subTexttextHeaderMissionVision: {
        color: '#7d7d7e',
        fontFamily: "Montserrat",
        fontSize: '1.3vw',
        fontWeight: 400,
        marginTop: '1vw'
    },

    textUniversities: {
        color: '#9167ac',
       fontFamily: "Montserrat",
        fontSize: '2.7vw',
        fontWeight: 700,
        marginTop: '3vw',
        marginBottom: '3vw',
    },

    gridcontainerSmallLogo: {
        marginTop: '1.5vw',
        display: 'flex',
        boxSizing: 'border-box' as 'border-box',
        alignItems: 'center',
        justifyContent: 'center'
    },
    stylesmallLogo: {
        padding: '2vw',
        width: '5vw',
        height: '5vw'
        // width: '3vw',
        // height: '3vw'
    },

    orange_strip: {
        marginTop: '3vw',
        width: '100%',
        height: '5vw',
        backgroundColor: '#fbaf3c',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    imgPersue: {
        margin: 0,
        padding: 0,
        width: '52vw',
        height: '42vw'
    },
    gridItemStyle: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center'
    },
    gridItemStyle2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    gridItemStyle3: {
        //backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    plusStyle: {
        margin: 0,
        padding: 0,
        width: '3.5vw',
        height: '3.5vw',
    },

    imgbkgFrist: {
        margin: 0,
        padding: 0,
        width: '18vw',
        height: '21vw',
    },
    imgFirst: {
        margin: 0,
        padding: 0,
        width: '15vw',
        height: '16vw',
    },
    imgbkgSecond: {
        margin: 0,
        padding: 0,
        width: '18vw',
        height: '21vw',
    },
    imgSecond: {
        margin: 0,
        padding: 0,
        width: '12.5vw',
        height: '13vw',
    },

    imgbkgThird: {
        margin: 0,
        padding: 0,
        width: '19vw',
        height: '21vw',
    },

    imgThird: {
        margin: 0,
        padding: 0,
        width: '15vw',
        height: '16vw',
    },
    imgbkgFourth: {
        margin: 0,
        padding: 0,
        width: '18vw',
        height: '21vw',
    },
    imgFourth: {
        margin: 0,
        padding: 0,
        width: '14vw',
        height: '16vw',
    },
    imgbkgFifth: {
        margin: 0,
        padding: 0,
        width: '18vw',
        height: '19vw',
    },
    imgFifth: {
        margin: 0,
        padding: 0,
        width: '11vw',
        height: '12vw'
    },
    modalStyle: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40vw',
        // height: '90vh',
        border: 'none',
        focus: 'none',
        borderRadius: '20px',
        paddingTop: '2px',
        paddingLeft: '4px',
        paddingRight: '4px',
        paddingBottom: '3px',
        backgroundColor: '#ffffff',
    },
    modalTextTitle: {
        color: '#171725',
        fontFamily: "Montserrat",
        fontSize: '26px',
        fontWeight: 600,
        lineHeight: '24px',
    },
    expoloreProgramBottomCenterBottomContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2.5vw',
    },
    expoloreProgramBottomCenterBottomContentFont: {
        color: '#9369AE',
        fontSize: '3.5vw',
        fontFamily: "Montserrat",
        fontWeight: 400,
        width: '75%',
    },
    quoteSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '75%',
        marginTop: '20px',
    },
    quoteName: {
        color: '#B8B8B8',
        fontFamily: "Montserrat",
        fontSize: '1.7vw',
        fontWeight: 1000,
        textAlign: 'right'
    },
    quoteAvatar: {
        width: '10vw',
        height: '10vw',
        marginLeft: '10px',
    },
    bookContainer: {
        marginTop: '4vw',
        display: 'flex',
        boxSizing: 'border-box',
        width: '85%',
        alignItems: 'stretch',
        justifyContent: 'space-between',
    },
    book1Container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    bookImg1: {
        marginTop: '-23px',
        padding: '0px',
        objectFit: 'cover',
        width: '33vw',
        height: '25vw',
    },
    bookImg2: {
        marginTop: '-23px',
        padding: '0px',
        objectFit: 'cover',
        height: '25vw',
    },
    bookImg3: {
        marginTop: '-23px',
        padding: '0px',
        objectFit: 'cover',
        height: '15vw',
    },
    downlodButton: {
        textTransform: 'none',
        marginTop: '1vw',
        width: '22vw',
        height: '3.5vw',
        borderRadius: '10px',
        backgroundColor: 'rgb(250, 176, 61)',
        color: 'rgb(255, 255, 255)',
        fontFamily: "Montserrat",
        fontSize: '1vw',
        fontWeight: 600,
    },
    buttonLink: {
        color: '#FFF',
        fontFamily: "Montserrat",
        fontSize: '1vw',
        fontWeight: 600,
        textDecoration: 'none',
    },
    HGContainer: {
        display: 'flex',
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
    },
    HgTitleGrid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    VideoContainer: {
        marginTop: '2vw',
        display: 'flex',
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
        width: '60%',
    },
    playerWrapper: {
        width: "100%",
        position: "relative",
        // cursor: "pointer"
    },
    ModalPlayerWrapper: {
        width: "100%",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '52px'
    },
    partnerContainer: {
        boxShadow: 'rgba(68, 68, 79, 0.1) 0px 5px 15px',
        backgroundColor: 'rgb(255, 255, 255)',
        marginTop: '1.5vw',
        display: 'flex',
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
    },
    partnerOuter: {
        height: '14vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    aboutHgContent: {
        marginTop: '3vw',
        display: 'flex',
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
    },
    abutHgContentText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    smallIconSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        border: '1px solid rgb(241, 241, 245)',
        borderRadius: '15px',
        backgroundColor: 'rgb(255, 255, 255)',
        boxShadow: 'rgba(68, 68, 79, 0.1) 0px 5px 15px',
    },
    missionContainer: {
        marginTop: '4vw',
        display: 'flex',
        boxSizing: 'border-box',
        width: '75%',
        alignItems: 'stretch',
    },
    missionOuter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    missionBox: {
        border: '1px solid rgb(241, 241, 245)',
        borderRadius: '15px',
        backgroundColor: 'rgb(255, 255, 255)',
        boxShadow: 'rgba(68, 68, 79, 0.1) 0px 5px 15px',
        padding: '1vw',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        width: '34vw',
        alignItems: 'center',
    },
    cancelButton: {
        position: "absolute",
        top: "-13px",
        background: "rgb(185, 142, 214)",
        color: "white",
        right: "-13px",
        borderRadius: '50%',
        border: 'none',
        cursor: 'pointer',  
        padding: '5px'
    },
    modalTitle: {
        fontFamily: "Montserrat",
        fontSize: '18px',
        fontWeight: 500,
        textAlign: 'center',
        color: 'rgb(23, 23, 37)',
        marginTop: '15px',
        marginBottom: '10px'
    },
    discription: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '7px',
        marginBottom: '1em'
    }
};
// Customizable Area End
