

import React from "react";

import {
  // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
     Grid,
     Card, CardContent,
      // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import HorizontalLine from "../../../components/src/HorizontalLine.web"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { HomeSchoolRecomender } from "../../dashboard/src/assets";

const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },

});


// Customizable Area End
 // Customizable Area Start
import Customform3Controller, {
    Props,
} from "./Customform3Controller";
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";
// Customizable Area End
export default class HomeschoolButtons extends Customform3Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

  renderCard(title:any, description:any, buttonColor:any, onClick:any){
    return (
      <Card
        style={{
          borderRadius: "12px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          margin: "16px",
        width: "100%",
        padding:'10px'
        }}
      >
       <div style={{ position: "relative" }}>
          <img
            src={HomeSchoolRecomender}
            alt={title}
            style={{ width: "100%", height: "200px", objectFit: "cover"}}
          />
          <div
            style={{
              position: "absolute",
              display: "flex",
              marginTop:"-35px",
               marginRight:"20px",marginLeft:"20px",
               justifyContent:"center",
               alignItems:"center"
            }}
          >
              <HomeOutlinedIcon
                  style={{ color: "#fff", marginRight: "8px" }}
                />
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color:"#ffff",
              }}
            >
              {title}
            </Typography>
          </div>
        </div>
        <CardContent>
          <div style={{ marginTop: "16px", marginBottom: "16px" }}>
            {description.map((item:any, index:any) => (
              <Typography
                key={index}
                variant="body2"
                style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
              >
                <CheckCircleIcon
                  style={{ color: "rgb(139, 195, 74)", marginRight: "8px" }}
                />
                {item}
              </Typography>
            ))}
          </div>
          {this.createBoxWithBreakline()}
           <Button
                                  style={{
                                    ...webStyle.HomeschoolProgram,
                                    backgroundColor: buttonColor,
                                  }}
                                  onClick={onClick}
                                  fullWidth
                                >
                                  GET STARTED
                                </Button>
        </CardContent>
      </Card>
    );
  }
  createBoxWithBreakline() {
    return (
      <Box style={webStyle.Breakline}>
        <p />
      </Box>
    );
  }
    // Customizable Area End

    render() {
        return (
          // Customizable Area Start
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AppWithoutDrawer navigation={this.props.navigation} />
            <Sidebarmenu activeKey="Overview" />
            <ThemeProvider theme={theme}>
              <Container maxWidth={false}>
                <Grid container style={{ display: "block" }}>
                  <Grid item>
                    <Container maxWidth={false}>

                        <Container
                          maxWidth={false}
                          style={{ margin: "0 auto" }}
                        >
                          <Box
                            style={{
                              marginTop: "80px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={webStyle.welcomeTextStyle}
                            >
                              Homeschool Recommender
                            </Typography>
                          </Box>
                          <Grid container style={{ display: "flex" }}>
                            <HorizontalLine />
                          </Grid>
                          <Container
                            maxWidth={false}
                            style={webStyle.GridWrapper}
                          >
                            <Box
                              style={{
                                flexWrap: "wrap",
                                ...webStyle.Recommendation,
                              }}
                            >
                              <Box
                                style={{
                                  ...webStyle.boxStyle,
                                }}
                              >
                                <Typography
                                  style={webStyle.Personalised}
                                >
                                  Get your Personalised Recommendation
                                </Typography>
                              </Box>
                              {/* <Box
                                style={{
                                  ...webStyle.boxStyle,
                                  paddingTop: '15px'
                                }}
                              > */}
                                {/* <Button
                                  style={{
                                    textTransform: "none",
                                    ...webStyle.HomeschoolProgram,
                                  }}
                                  onClick={() =>
                                    this.handleHomeschoolProgramNavigate()
                                  }
                                >
                                  Homeschool Program
                                </Button>
                                <Button
                                  style={{
                                    textTransform: "none",
                                    ...webStyle.HomeschoolStyle,
                                  }}
                                  onClick={() =>
                                    this.handleHomeschoolStyleNavigate()
                                  }
                                >
                                  Homeschool Style
                                </Button> */}
                                <div style={{ display: "flex", justifyContent: "center", flexWrap: "nowrap"}}>
        {this.renderCard(
          "Homeschool Program",
          [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur.",
          ],
       "#8BC34A",
          this.handleHomeschoolProgramNavigate
        )}
        {this.renderCard(
          "Homeschool Style",
          [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur.",
          ],
          "#673AB7",
          this.handleHomeschoolStyleNavigate
        )}
      </div>
                              {/* </Box> */}
                            </Box>
                          </Container>
                        </Container>
                    </Container>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  style={{
                    display: "flex",
                    alignItems: "stretch",
                    marginTop: "2em",
                  }}
                >
                  <Grid item xs={12} sm={6} md={4} lg={3} />
                  <Grid item xs={12} sm={6} md={4} lg={3} />
                </Grid>
              </Container>
            </ThemeProvider>
          </div>
          // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    Recommendation: {
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    Personalised: {
        color: "#171725",
        fontFamily: "Montserrat", 
        fontSize: "22px",
        // fontSize: "20px",
        fontWeight: 600,
        //marginRight: '2em',
        padding:0,
        margin:0
    },
    HomeschoolProgram: {
        height: "3.0vw",
        fontFamily: "Montserrat", 
        fontSize: "1.35vw",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "16px",
        backgroundColor: "#9167ac",
        marginTop: '1.1em',
        padding: '0 30px'
    },
    boxStyle:{
        padding:0,
        margin:0
    },
    HomeschoolStyle: {
        height: "3.5vw",
        fontFamily: "Montserrat", 
        fontSize: "1.35vw",
        color: "#fafafb",
        fontWeight: 600,
        borderRadius: "16px",
        backgroundColor: "#fab03d",
        margin: '0.1em',
        marginLeft: '1em',
        padding: '0 30px'
    },
    welcomeTextStyle: {
        color: '#171725',
        fontFamily: "Montserrat", 
        fontSize: '24px',
        fontWeight: 600,
    },
    GridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
        marginTop: "2em",
        padding:'15px',
        boxShadow: "rgba(68, 68, 79, 0.1) 0px 5px 15px"
    },
    Breakline: {
      width: '100%', height: '1px', backgroundColor: '#dcdcdc',
  },
};
// Customizable Area End
